import axios from 'axios'
import Cookie from 'js-cookie'
import store from '@/state/store'
import Swal from 'sweetalert2';

let user = { token: Cookie.get('_easyindustria_token') };
const API = `${process.env.VUE_APP_BASEURI}/api`;
store.dispatch('notification/clear', { root: true });

const http = axios.create({
  baseURL: API,
  headers: {
    Authorization: 'Bearer ' + `${user.token}`,
    ContentType: 'application/json',
    Accept: 'application/json'
  }
});

http.interceptors.response.use(function (response) {
  return response;
},
  function (error) {
    if (error.response) {
      const { data, status } = error.response;
      Swal.close();

      if (status === 401 && data.message === 'Unauthenticated.') {
        Swal.fire({
          icon: "warning",
          title: "Acesso negado",
          text: `Token inválido ou expurado \n Faça login novamente.`,
        });

        store.dispatch('notification/error', 'Usuário não autenticado!', { root: true });
        localStorage.removeItem('user');
        Cookie.remove('_easyindustria_token');
        location.reload(true);
      }

      if (status === 403 && data.message === 'This action is unauthorized.') {
        Swal.fire({
          icon: "warning",
          title: "Acesso negado",
          text: `Perfil não tem permissão! \n Por favor, contate o administrador.`,
        });
        store.dispatch('notification/error', 'Usuário não autorizado!', { root: true });
      }
    }
    return Promise.reject(error);
  }
)

export { http, API }